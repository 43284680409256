import React from 'react'
import { Link } from 'react-router-dom'
import Page from 'src/components/Page/index.js'
import List from 'src/components/ui/List/List.js'
import ListItem from 'src/components/ui/List/ListItem.js'
import Typography from 'src/components/ui/Typography/index.js'
import staticStyles from 'src/modules/Static/Static.module.sass'
import styles from './NoReportsFound.module.sass'
import SectionHeading from 'src/components/SectionHeading/index.js'
import Metadata from 'src/components/Metadata/index.js'
import cx from 'classnames'
import usePiwik from 'src/hooks/usePiwik'

const NoReportsFound = () => {
  const { handleTracking } = usePiwik()
  return (
    <Page className={staticStyles.static}>
      <Metadata title="YOUR REPORTS" />
      <SectionHeading title="YOUR REPORTS" gutterBottom paragraph />

      <Typography
        type="body1"
        className={cx(staticStyles.paragraph, styles.infoParagraph)}
        paragraph
      >
        You currently do not have any FirstWord Reports available to you.{' '}
        <Link
          to="/contact-us"
          className={styles.link}
          onClick={() => {
            handleTracking('page', 'click', 'your-reports_contact-us')
          }}
        >
          Contact us
        </Link>{' '}
        to learn more about our reports and how you gain access.
      </Typography>

      <div className={styles.buttonsContainer}>
        <Link
          to="/contact-us"
          className={cx(staticStyles.button)}
          onClick={() => {
            handleTracking(
              'page',
              'button-click',
              'your-reports_contact-us-to-learn-more'
            )
          }}
        >
          Contact Us to Learn More
        </Link>
      </div>

      <Typography type="body1" paragraph className={staticStyles.paragraph}>
        FirstWord Reports offers an extensive collection of reports designed to
        equip your team with the critical intelligence necessary to maintain a
        competitive edge and make sense of the constantly evolving
        pharmaceutical industry. FirstWord can help your company achieve
        accelerated growth by delivering data and insights that enable you to:
      </Typography>

      <div className={styles.listContainer}>
        <List className={staticStyles.list} listStyle="outside">
          <ListItem>Understand emerging market trends</ListItem>
          <ListItem>Anticipate consumer behaviour & interest</ListItem>
          <ListItem>Identify sales opportunities</ListItem>
        </List>

        <List className={staticStyles.list} listStyle="outside">
          <ListItem>Improve operational performance</ListItem>
          <ListItem>and much more!</ListItem>
        </List>
      </div>

      <Typography type="body1" paragraph className={staticStyles.paragraph}>
        Covering over 30 major disease areas and providing physician
        intelligence and KOL insights on issues in{' '}
        <strong>
          market access, commercialisation, medical affairs, digital health,
          technology
        </strong>
        , and <strong>market research</strong>, FirstWord Reports empowers your
        team to make smarter, data-driven decisions by answering your most
        challenging business questions about your products, competitors, and
        markets. Elevate your decision-making process and position your company
        for unparalleled success with FirstWord Reports.
      </Typography>
    </Page>
  )
}
export default NoReportsFound
