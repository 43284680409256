// React and libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
// Componentes
import ActionIcon from 'src/components/ActionButtons/components/ActionIcon'
import ShareModal from 'src/components/ActionButtons/components/ShareModal'
import EmailShareModal from 'src/components/ActionButtons/components/EmailShareModal/index.js'
import ShareConfirmationModal from 'src/components/ActionButtons/components/EmailShareModal/ShareConfirmationModal.js'
// Utils and fucntions
import copyToClipboard from 'src/utils/copyToClipboard'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { socialMediaInfo } from 'src/utils/oneCodeBase.js'
import { actions } from '../../reducer'
import usePiwik from 'src/hooks/usePiwik'

const ShareActionButton = ({
  articleId,
  articleTitle = '',
  articleUrl = '',
  actionButtonsSettings = {},
  articleBody = '',
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [articleModalOpen, setArticleModalOpen] = useState(false)
  const [siteModalOpen, setSiteModalOpen] = useState(false)
  const [confirmationShareModal, setConfirmationShareModal] = useState(false)
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const { share_button = {} } = actionButtonsSettings

  const confirmationModal = useSelector(
    state => state.actionButtonsReducer.confirmationModal
  )

  useEffect(() => {
    setConfirmationShareModal(confirmationModal)
  }, [confirmationModal])

  const dispatch = useDispatch()

  const handleOpenModal = () => {
    setModalOpen(true)
    handleTrackingWithArticleIdProp('pop-up_share', 'view', 'share', articleId)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    handleTrackingWithArticleIdProp(
      'pop-up_share',
      'click',
      'close-window',
      articleId
    )
  }

  const handleCloseEmailModal = () => {
    setArticleModalOpen(false)
    handleTrackingWithArticleIdProp(
      'pop-up_share-email',
      'click',
      'close-window',
      articleId
    )
  }

  const handleSiteModalOpen = () => {
    // Tracks the user click
    handleTrackingWithArticleIdProp(
      'pop-up_share',
      'button-click',
      'invite-colleague',
      articleId
    )
    // tracks modal was opened
    handleTrackingWithArticleIdProp(
      'pop-up_share-invite-colleague',
      'view',
      'share_invite-colleague',
      articleId
    )
    setSiteModalOpen(true)
  }

  const handlesiteModalClose = () => {
    setSiteModalOpen(false)
    handleTrackingWithArticleIdProp(
      'pop-up_share-invite-colleague',
      'click',
      'close-window',
      articleId
    )
  }

  const handleConfirmationModalClose = () => {
    dispatch(actions.closeConfirmationModal())
  }

  const getUrl = () => {
    const urlOriginLocation = window.location.origin
    const urlPath = window.location.pathname
    const isArticlePageRegex = /^\/story\/\d+$/
    if (isArticlePageRegex.test(urlPath)) {
      return `${urlOriginLocation}${urlPath}`
    }
    return `${urlOriginLocation}${articleUrl}`
  }

  const handleSocialIconClick = socialNetwork => e => {
    e.preventDefault()
    let title = articleTitle
    let body = articleBody

    if (socialNetwork === 'link') {
      copyToClipboard(getUrl())
      dispatch(
        notificationsEnqueue({
          message: 'URL was copied to your clipboard'
        })
      )

      handleTrackingWithArticleIdProp(
        'pop-up_share',
        'button-click',
        'url',
        articleId
      )
      return
    }

    if (socialNetwork === 'email') {
      // Tracks user click
      handleTrackingWithArticleIdProp(
        'pop-up_share',
        'button-click',
        'email',
        articleId
      )
      setArticleModalOpen(true)
      // Tracks modal was opened
      handleTrackingWithArticleIdProp(
        'pop-up_share-email',
        'view',
        'share_email',
        articleId
      )
      return
    }

    const linkBuilders = {
      twitter: () => {
        let message = ''
        message = encodeURIComponent(
          `${title} ${getUrl()} via ${socialMediaInfo?.twitter}`
        )

        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        if (body && body.length > 50) {
          body = body.substring(0, 50) + '...'
        }

        return `http://www.linkedin.com/shareArticle?mini=true&url=${getUrl()}&title=${encodeURIComponent(
          title
        )}&summary=${encodeURIComponent(body)}&source=${
          socialMediaInfo?.linkedin
        }`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    handleTrackingWithArticleIdProp(
      'pop-up_share',
      'button-click',
      socialNetwork === 'twitter' ? 'x-twitter' : 'linkedin',
      articleId
    )
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }

  return (
    <>
      <ActionIcon
        iconPath="svg/material-design-icons/content/share"
        action={handleOpenModal}
        articleId={articleId}
        {...rest}
      />

      <ShareModal
        modalOpen={modalOpen}
        onClose={handleModalClose}
        handleSocialIconClick={handleSocialIconClick}
        articleTitle={articleTitle}
        modalInfo={share_button?.share_modal}
        handleSiteModalOpen={handleSiteModalOpen}
      />

      {articleModalOpen && (
        <EmailShareModal
          articleId={articleId}
          open={articleModalOpen}
          onClose={handleCloseEmailModal}
          actionType="article-share"
          modalInfo={share_button?.article_modal}
          initialValues={{
            email_message: `${share_button?.article_modal?.message_value} ${articleTitle}`
          }}
          form="article-share"
        />
      )}

      {siteModalOpen && (
        <EmailShareModal
          articleId={articleId}
          open={siteModalOpen}
          onClose={handlesiteModalClose}
          actionType="site-share"
          modalInfo={share_button?.site_modal}
          initialValues={{
            email_message: `${share_button?.site_modal?.message_value}`
          }}
          form="site-share"
        />
      )}

      {confirmationShareModal && (
        <ShareConfirmationModal
          open={confirmationShareModal}
          onClose={handleConfirmationModalClose}
          modalInfo={share_button?.site_modal}
          withBackground={false}
          modalStyles={{ backgroundColor: 'unset' }}
          modalContentStyles={{ boxShadow: 'unset' }}
        />
      )}
    </>
  )
}

ShareActionButton.propTypes = {
  articleId: PropTypes.any,
  articleTitle: PropTypes.string,
  articleUrl: PropTypes.string,
  articleBody: PropTypes.string,
  actionButtonsSettings: PropTypes.object
}

export default ShareActionButton
