import React, { useContext } from 'react'
import { SessionContext } from 'src/modules/Session/context'
import AnonReports from 'src/modules/MyReports/components/AnonReports/index.js'
import ProfileReports from './components/ProfileReports'

const MyReports = props => {
  const { authenticated, checked: sessionReady } = useContext(SessionContext)

  // Wait for session to be ready before evaluating
  if (!sessionReady) return

  // If use is anonymous
  if (!authenticated) {
    return <AnonReports {...props} />
  }

  return <ProfileReports {...props} />
}

export default MyReports
