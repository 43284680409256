import React from 'react'
import PropTypes from 'prop-types'
import { CustomizableModal } from 'src/components/CustomizableModal'
import Typography from 'src/components/ui/Typography/index.js'
import styles from '../EmailShareModal/EmailShareModal.module.sass'

const ShareConfirmationModal = ({ open = false, onClose = () => {} }) => {
  return (
    <CustomizableModal
      open={open}
      onClose={onClose}
      modalStyles={{ zIndex: '82' }}
      modalContentStyles={{ padding: '20px', textAlign: 'center' }}
      width="500px"
      height="auto"
    >
      <div className={styles.modalContent}>
        <Typography
          type="title"
          gutterBottom
          paragraph
          className={styles.confirmationTitle}
        >
          <strong>Thank you for sharing!</strong>
        </Typography>
        <Typography
          type="body"
          paragraph
          className={styles.confirmationParagraph}
        >
          A message has been sent to the recipient(s).
        </Typography>
      </div>
    </CustomizableModal>
  )
}

ShareConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default ShareConfirmationModal
