import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import View from 'src/components/View/index.js'
import ViewMoreBox from 'src/components/ViewMoreBox/index.js'

// Story Components
import StoryRiver from 'src/components/StoryRiver/index.js'
import StoryExcerpt from 'src/components/StoryExcerpt/index.js'

const StoryRiverView = ({
  title,
  to = null,
  typeTo = null,
  viewMore = {},
  titleMode = null,
  featured = false,
  imagesLength = null,
  view,
  viewsError,
  viewsLoading,
  handleViewMoreClick,
  showInterests = false,
  showTeaser = true,
  showPublished = true,
  articlesLength = null,
  tagsClickable = true,
  interestsClickable = true,
  showActionBar = false,
  includeTags = true,
  isReversed = false,
  showBody = true,
  publishedPosition = 'bottom',
  match = null,
  actionButtonsConfig = {},
  featuredSettings = {},
  sidebar = false,
  objectFit = 'cover',
  showTitleOnly = false,
  ...props
}) => {
  const getArticleId = () => {
    if (!match) return
    const { params = {} } = match
    return params.id
  }

  if (viewMore?.to === '/river/related-news/') {
    viewMore.to = `/river/related-news/${getArticleId()}`
  }
  return (
    <StoryRiver title={ReactHtmlParser(title)}>
      <View
        error={viewsError}
        loading={viewsLoading}
        view={view}
        render={(story, i) => {
          return (
            (!articlesLength || eval(articlesLength)) && (
              <StoryExcerpt
                key={story.id}
                id={story.id}
                to={to || `/story/${story.id}`}
                tags={story.tags || []}
                title={story.title}
                body={showTeaser ? story.teaser : ''}
                published={showPublished ? story.published : null}
                titleMode={titleMode}
                type={story.type}
                typeTo={
                  tagsClickable === false
                    ? null
                    : typeTo || `/river/type/${story.type}`
                }
                congress={story.congress}
                plus={story?.flags?.isPaid ?? false}
                featured={featured}
                image={story?.image}
                showImage={imagesLength && eval(imagesLength)}
                interestsClickable={interestsClickable}
                showBody={showBody}
                showInterests={showInterests}
                showActionBar={showActionBar}
                includeTags={includeTags}
                isReversed={isReversed}
                publishedPosition={publishedPosition}
                actionButtonsConfig={actionButtonsConfig}
                featuredSettings={featuredSettings}
                reportSettings={story?.reportSettings}
                sidebar={sidebar}
                objectFit={objectFit}
                likes={story.likes}
                likedByProfile={story.likedByProfile}
                showTitleOnly={showTitleOnly}
              />
            )
          )
        }}
      />
      {viewMore?.show && (
        <ViewMoreBox
          onClick={() => handleViewMoreClick(viewMore.handleMore)}
          to={viewMore.to}
        >
          View More {ReactHtmlParser(viewMore.title || title)}
        </ViewMoreBox>
      )}
    </StoryRiver>
  )
}

StoryRiverView.propTypes = {
  title: PropTypes.node,
  to: PropTypes.string,
  typeTo: PropTypes.string,
  viewMore: PropTypes.object,
  titleMode: PropTypes.string,
  featured: PropTypes.bool,
  imagesLength: PropTypes.any,
  view: PropTypes.object,
  viewsError: PropTypes.string,
  viewsLoading: PropTypes.bool,
  handleViewMoreClick: PropTypes.func,
  showInterests: PropTypes.bool,
  showTeaser: PropTypes.bool,
  showPublished: PropTypes.bool,
  articlesLength: PropTypes.string,
  tagsClickable: PropTypes.bool,
  interestsClickable: PropTypes.bool,
  showActionBar: PropTypes.bool,
  includeTags: PropTypes.bool,
  isReversed: PropTypes.bool,
  showBody: PropTypes.bool,
  publishedPosition: PropTypes.string,
  match: PropTypes.object,
  actionButtonsConfig: PropTypes.object,
  featuredSettings: PropTypes.object,
  sidebar: PropTypes.bool,
  objectFit: PropTypes.string,
  showTitleOnly: PropTypes.bool
}

export default StoryRiverView
