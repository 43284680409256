import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from 'src/components/SingleStory/SingleStory.module.sass'
import RequestAccessModal from 'src/modules/Paywall/Components/RequestModal'
import UpcomingAndAuthenticated from './children/UpcomingAndAuthenticated'
import UpcomingAndAnonymous from './children/UpcomingAndAnonymous'
import HasAccess from './children/HasAccess'
import DoesNotHaveAccess from './children/DoesNotHaveAccess'
import AnonymousAndNotUpcoming from './children/AnonymousAndNotUpcoming'
import usePiwik from 'src/hooks/usePiwik'

const ReportButtons = ({
  articleId,
  user = null,
  authenticated = false,
  upcomingAuthenticatedSettings = {},
  reportSettings = {}
}) => {
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState('')
  const upcoming = reportSettings?.upcoming ?? false
  const hasAccess = reportSettings?.hasAccess ?? false
  const [disableConsultantContact, setDisableConsultantContact] =
    useState(false)

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    handleTrackingWithArticleIdProp(
      modalType === 'notify-me'
        ? 'pop-up'
        : 'pop-up_contact-me-about-this-report',
      'click',
      'close-window',
      articleId
    )
    setModalVisible(false)
  }

  const openModalWithTracking = (
    trackingType,
    disableConsultantContact,
    modalType = 'contact-me'
  ) => {
    const trackingOptions = {
      'contact-me-about-this-report': {
        category: 'pop-up_contact-me-about-this-report',
        action: 'view',
        actionType: 'contact-me-about-this-report'
      },
      'notify-me': {
        category: 'pop-up',
        action: 'view',
        actionType: 'notify-me-anon-form'
      }
    }

    if (trackingType in trackingOptions) {
      const { category, action, actionType } = trackingOptions[trackingType]
      handleTrackingWithArticleIdProp(category, action, actionType, articleId)
    }
    setModalType(modalType)
    setDisableConsultantContact(disableConsultantContact)
    openModal()
  }

  const withSubmitTracking = () => {
    handleTrackingWithArticleIdProp(
      modalType === 'notify-me'
        ? 'pop-up'
        : 'pop-up_contact-me-about-this-report',
      'button-click',
      modalType === 'notify-me' ? 'notify-me-anon-form-submit' : 'submit',
      articleId
    )
  }

  const renderButtons = () => {
    // Scenario 1
    if (upcoming && authenticated) {
      return (
        <div className={styles.upcomingAndAuthenticated}>
          <UpcomingAndAuthenticated
            contactAction={openModalWithTracking}
            articleId={articleId}
            user={user}
            {...upcomingAuthenticatedSettings}
            {...reportSettings}
          />
        </div>
      )
    }
    // Scenario 2
    if (upcoming && !authenticated)
      return (
        <UpcomingAndAnonymous
          openModal={openModalWithTracking}
          articleId={articleId}
        />
      )
    // Scenario 3
    if (!upcoming && authenticated && hasAccess)
      return <HasAccess articleId={articleId} reportSettings={reportSettings} />
    // Scenario 4
    if (!upcoming && authenticated && !hasAccess)
      return (
        <DoesNotHaveAccess
          openModal={openModalWithTracking}
          articleId={articleId}
        />
      )
    // Scenario 5
    if (!upcoming && !authenticated)
      return (
        <AnonymousAndNotUpcoming
          openModal={openModalWithTracking}
          articleId={articleId}
        />
      )
  }

  return (
    <>
      {renderButtons()}
      <RequestAccessModal
        open={modalVisible}
        onCancel={closeModal}
        disableConsultantContact={disableConsultantContact}
        withSubmitTracking={withSubmitTracking}
      />
    </>
  )
}

ReportButtons.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool,
  upcomingAuthenticatedSettings: PropTypes.object,
  articleId: PropTypes.any,
  reportSettings: PropTypes.object
}

export default ReportButtons
