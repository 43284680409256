import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import NoReportsFound from '../NoReportsFound'
import River from 'src/modules/River/components/River'
import { actions } from 'src/modules/MyReports/reducer'
import StorySpinner from 'src/components/StorySpinner/index.js'
import Page from 'src/components/Page/index.js'

const ProfileReports = props => {
  const dispatch = useDispatch()
  const { loading = null, data: reportsCount } = useSelector(
    state => state.myReports.count,
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.requestReportsCount())
  }, [])

  if (loading !== false) {
    return (
      <Page>
        <StorySpinner />
      </Page>
    )
  }

  if (reportsCount && reportsCount > 0) {
    return <River />
  }
  return <NoReportsFound {...props} />
}

ProfileReports.propTypes = {}

export default ProfileReports
