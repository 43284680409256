import React, { Fragment, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { SessionContext } from 'src/modules/Session/context'
import DropDown from 'src/components/ui/DropDown/index.js'
import { Input } from 'src/components/ui/Input/index.js'
import Typography from 'src/components/ui/Typography/index.js'
import Spinner from 'src/components/ui/Spinner/index.js'
import { Link, Prompt } from 'react-router-dom'
import { Field, submit, change, Fields } from 'redux-form'
import commonStyles from 'src/styles/common.module.sass'
import _get from 'lodash/get'
import styles from './MyAccountForm.module.sass'
import FieldTooltip from '../FieldTooltip'
import cx from 'classnames'

const AutoSubmitField = props => {
  const dispatch = useDispatch()
  const [controlledValue, setControlledValue] = useState('')

  useEffect(() => {
    if (controlledValue) {
      dispatch(submit('updateAccount'))
    }
  }, [controlledValue])

  const submitMethod = () => {
    const isDropDown = props.component.displayName === 'DropDown'
    const method = isDropDown ? 'onChange' : 'onBlur'

    const submitForm = event => {
      const inputValue = isDropDown ? event : event.target.value
      if (inputValue !== props.meta.initial) {
        dispatch(change('updateAccount', props.name, inputValue))
        setControlledValue(inputValue)
      }
    }

    return {
      [method]: submitForm
    }
  }

  const loading = props.meta.visited && props.meta.dirty && props.meta.touched
  const error = props.meta.error
  return (
    <div className={styles.half}>
      <Field {...props} {...submitMethod()} />
      {!error && loading && (
        <Spinner className={styles.loading} size={24} fillColor="transparent" />
      )}
    </div>
  )
}

AutoSubmitField.propTypes = {
  component: PropTypes.any,
  meta: PropTypes.object,
  name: PropTypes.string
}

const RenderFields = props => {
  const {
    initialValues,
    countryOptions,
    jobFunctionOptions,
    fields = []
  } = props
  const { userHasLicense = () => false, user } = useContext(SessionContext)
  const isPlus = userHasLicense(licenseTypes.LICENSE_PLUS)

  return (
    <Fragment>
      {fields.includes('email') && (
        <div data-clarity="mask">
          <Field
            component={Input}
            label="Email Address"
            name="email"
            errorAlign="center"
            readOnly
            labelTooltip={<FieldTooltip field="email" />}
          />
        </div>
      )}
      {fields.includes('first_name') && (
        <div className={styles.half} data-clarity="mask">
          <Field
            component={Input}
            label="First Name"
            name="first_name"
            errorAlign="center"
            readOnly
            labelTooltip={<FieldTooltip field="name" />}
          />
        </div>
      )}
      {fields.includes('last_name') && (
        <div className={styles.half} data-clarity="mask">
          <Field
            component={Input}
            label="Last Name"
            name="last_name"
            errorAlign="center"
            readOnly
            labelTooltip={<FieldTooltip field="name" />}
          />
        </div>
      )}
      <div>
        {fields.includes('country') && (
          <AutoSubmitField
            component={DropDown}
            label="Country"
            options={countryOptions}
            name="country"
            errorAlign="center"
            autoComplete="disable"
            className={styles.select}
            meta={props.country.meta}
          />
        )}
        {fields.includes('company') && (
          <AutoSubmitField
            component={Input}
            label="Company"
            name="company"
            errorAlign="center"
            meta={props.company.meta}
          />
        )}
        {fields.includes('job_title') && (
          <AutoSubmitField
            component={Input}
            label="Job Title"
            name="job_title"
            errorAlign="center"
            meta={props.job_title.meta}
          />
        )}
        {fields.includes('job_function') && (
          <AutoSubmitField
            component={DropDown}
            label="Job Function"
            options={jobFunctionOptions}
            name="job_function"
            errorAlign="center"
            autoComplete="disable"
            className={styles.select}
            meta={props.job_function.meta}
          />
        )}
        {fields.includes('phone_number') && (
          <AutoSubmitField
            component={Input}
            label="Phone Number"
            name="phone_number"
            errorAlign="center"
            type="tel"
            meta={props.phone_number.meta}
          />
        )}
      </div>
      {fields.includes('subscription_type') && (
        <div className={styles.half}>
          <Field
            component={Input}
            label="Subscription Type"
            name="subscription_type"
            errorAlign="center"
            readOnly
          />
        </div>
      )}
      {isPlus &&
        initialValues.subscription_expiry &&
        fields.includes('subscription_expiry') && (
          <div className={styles.half}>
            <Field
              component={Input}
              label="Subscription Expiry"
              name="subscription_expiry"
              errorAlign="center"
              readOnly
            />
          </div>
        )}
      {user?.premiumAccountConsultant?.active ? (
        <div>
          <Typography
            type="body1"
            align="left"
            className={cx(styles.info, styles.marginTop)}
          >
            For more information on this subscription, please contact your
            FirstWord Consultant:
            <div className={styles.marginTop}>
              {user.premiumAccountConsultant.first_name}{' '}
              {user.premiumAccountConsultant.last_name}
            </div>
            <div>{user.premiumAccountConsultant.phone_number}</div>
            <div>
              <Link to="/contact-us/subscriber" className={styles.link}>
                {user.premiumAccountConsultant.email}
              </Link>
            </div>
          </Typography>
        </div>
      ) : (
        <Typography type="body1" align="left" className={styles.info}>
          <br />
          For more information, please{' '}
          <Link to="/contact-us/subscriber" className={styles.link}>
            contact us
          </Link>
        </Typography>
      )}
    </Fragment>
  )
}

RenderFields.propTypes = {
  initialValues: PropTypes.object,
  countryOptions: PropTypes.object,
  jobFunctionOptions: PropTypes.object,
  fields: PropTypes.array,
  country: PropTypes.object,
  company: PropTypes.object,
  job_title: PropTypes.object,
  job_function: PropTypes.object,
  phone_number: PropTypes.object
}

const MyAccountForm = ({
  handleSubmit,
  fields,
  catalogs = {},
  initialValues,
  isDirty
}) => {
  const countryOptions = _get(catalogs, 'country.options', {})
  const jobFunctionOptions = _get(catalogs, 'jobFunctions.options', {})

  return (
    <div className={commonStyles.centered} data-private>
      <Prompt when={isDirty} message="Changes you made may not be saved." />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Fields
          countryOptions={countryOptions}
          jobFunctionOptions={jobFunctionOptions}
          names={[
            'email',
            'first_name',
            'last_name',
            'country',
            'company',
            'job_title',
            'job_function',
            'phone_number',
            'subscription_type',
            'subscription_expiry'
          ]}
          fields={fields}
          component={RenderFields}
          initialValues={initialValues}
        />
      </form>
    </div>
  )
}

MyAccountForm.propTypes = {
  handleSubmit: PropTypes.func,
  fields: PropTypes.array,
  catalogs: PropTypes.object,
  initialValues: PropTypes.object,
  isDirty: PropTypes.bool
}

export default MyAccountForm
