import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import _uniqWith from 'lodash/uniqWith'
import _isEqual from 'lodash/isEqual'
import DOMPurify from 'dompurify'
import normalizeUrl from 'normalize-url'
import { siteName } from 'src/utils/oneCodeBase'

const AUTOTEASER_SIZE = 200

const FIRSTWORD_ARTICLE_URL =
  /(https?:\/\/www.firstwordpharma.com\/node\/(\d+))/
class StoryParser {
  constructor(LabelReplacer, LegacyLinkResolver, config, ConfigRepository) {
    this.labelReplacer = LabelReplacer
    this.legacyLinkResolver = LegacyLinkResolver
    this.config = config
    this.configRepository = ConfigRepository
  }

  async rawLinkResolver(body) {
    try {
      const domParser = new DOMParser()
      const doc = domParser.parseFromString(body, 'text/html')
      return doc.body.innerHTML
    } catch (e) {
      console.warn('Unable to parse body: %o', e)
    }
    return body
  }

  async normalizeViewSet(views) {
    return await Promise.all(
      views.map(async ({ name, data }) => ({
        name,
        data: await this.normalizeView(data)
      }))
    )
  }

  async normalizeView(articles) {
    return await Promise.all(
      articles.map(article => this.normalizeArticle(article, false))
    )
  }

  getAuthor({ author, authors }) {
    let allAuthors = []
    if (author?.trim()) {
      allAuthors.push(author.trim())
    }
    if (authors) {
      allAuthors = [
        ...allAuthors,
        ...authors.map(author => `${author.ForeName} ${author.LastName}`)
      ]
    }
    return allAuthors.length ? allAuthors.join(', ') : ''
  }

  uniqueTags(tags) {
    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    // Use lodash _uniqWith to remove duplicated elements passing a comparator
    // We use _isEqual comparator to perform a deep comparison between objects
    return _uniqWith(
      Object.keys(tags).reduce((acc, key) => {
        if (validTagCategories.indexOf(key) === -1) return acc
        return [...acc, ...tags[key].map(tag => ({ category: key, tag }))]
      }, []),
      _isEqual
    )
  }

  getTextFromHtml(html) {
    const container = document.createElement('body')
    container.innerHTML = html
    return container.innerText || container.textContent
  }

  async normalizeArticle(fbarticle, resolveLinks = true) {
    // Defaults
    let {
      id,
      title = '',
      body = '',
      type = '',
      tags = {},
      extra_fields: extra = {},
      publication_date_time,
      updated_date_time,
      likes = 0,
      liked_by_profile = false,
      has_access = false
    } = fbarticle

    let reportSettings = null
    let image = extra?.article_image_url
    let teaser = extra.teaser || ''

    // Firsword Reports specific fields
    if (siteName === 'Reports') {
      reportSettings = {
        upcoming: extra?.preview === true && extra?.private === false,
        preview: extra?.preview,
        productCode: extra?.product_code,
        parentProductCode: extra?.parent_product_code,
        reportCategory: extra?.report_category,
        reportType:
          tags?.fw_report_type?.length > 0 ? tags?.fw_report_type[0] : null,
        private: extra?.private,
        onDemand: extra?.on_demand,
        keyQuestions: extra?.key_questions,
        keyBrands: extra?.key_brands,
        participatingExperts: extra?.participating_experts,
        reportUrl: extra?.report_url,
        fwReportTeaser: extra?.fw_report_teaser,
        reportBrand:
          tags?.fw_report_brand?.length > 0 ? tags?.fw_report_brand[0] : null,
        hasAccess: !!has_access,
        diseaseAreas: tags?.fw_report_disease_areas ?? []
      }

      if (extra?.fw_report_teaser && extra?.fw_report_teaser !== 'null') {
        teaser = extra.fw_report_teaser
      }

      image = extra?.image_url
    }

    let content = body || ''
    let source = ''
    let referenceArticles = []
    const publishedTimestamp = _get(publication_date_time, 'timestamp', 0)
    const updatedTimestamp = _get(updated_date_time, 'timestamp', 0)
    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    const mediaEventArticleTypes =
      contentSettings?.media_event_article_types ?? []
    const categorizedTags = Object.keys(tags).reduce((acc, key) => {
      if (validTagCategories.indexOf(key) === -1) return acc
      return {
        ...acc,
        [key]: _uniq(tags[key])
      }
    }, {})
    const arrayTags = this.uniqueTags(tags)
    const origin = tags.fw_destination || []
    let url = null
    try {
      const { article_url, url: extra_url } = extra
      if (article_url) {
        url = normalizeUrl(article_url)
      } else if (extra_url) {
        url = normalizeUrl(extra_url)
      }
    } catch (e) {
      console.log(e)
    }
    const shouldResolveLinks =
      resolveLinks && this.config.FEATURE_LEGACY_LINK_RESOLVER !== '0'
    if (type === 'DGDispatch') content = content.replace(/\n/g, '<br />')
    let formattedBody = DOMPurify.sanitize(content)
    let formattedTeaser = DOMPurify.sanitize(teaser)
    if (shouldResolveLinks) {
      formattedBody = await this.rawLinkResolver(content)
    }
    if (type === 'DGAbstract') {
      source = _get(tags, 'journal[1]', '')
      referenceArticles = [
        { name: 'PubMed', title: source, url: extra.article_url }
      ]
    } else {
      source = extra.sources
        ? extra.sources
            .map(source => source.name)
            .reduce(
              (acc, source) =>
                acc.indexOf(source) > -1 ? acc : [...acc, source],
              []
            )
            .join(', ')
        : ''
      referenceArticles = extra.sources
        ? extra.sources.filter(({ name, title, url }) => name && title && url)
        : []
    }

    return {
      id,
      title,
      body: formattedBody,
      teaser:
        this.getTextFromHtml(formattedTeaser) ||
        this.getTextFromHtml(formattedBody).substring(0, AUTOTEASER_SIZE),
      congress: tags.congress || null,
      published: publishedTimestamp,
      updated: updatedTimestamp,
      // Authors: Names separated with ";"
      author: this.getAuthor(extra),
      // Sources: Using deduped names separated with ","
      source,
      // Reference Articles:
      referenceArticles,
      url: url,
      image,
      tags: arrayTags,
      allTags: tags._all,
      categorizedTags,
      type: this.labelReplacer.getForUI(type),
      flags: {
        isPaid: extra.is_paid || false
      },
      mediaEvent: mediaEventArticleTypes.includes(type) ? extra : {},
      reportSettings,
      origin,
      likes,
      likedByProfile: !!liked_by_profile
    }
  }
}

export default StoryParser
